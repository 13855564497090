
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "level-badge",
  props: {
    experience: Object
  },
  computed: {
    ...mapGetters("LevelsModule", ["getData"]),
    levelByID() {
      let data: any[] = [];
      data = this.getData;
      let id = NaN;
      id = this.experience?.level_id;
      if (id) return data.find((a: { id: number }) => a.id == id)?.name;
      else return "No level";
    }
  }
});
