
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";

export default defineComponent({
  name: "SkillsDropdown",
  data: () => ({
    parentCat: "" as any,
    children: [] as any[],
    value: ""
  }),
  props: {
    error: Boolean,
    currentSkills: Array
  },
  methods: {
    ...mapActions("ListsModule", ["fetchSkills"])
  },
  mounted() {
    this.fetchSkills();
  },
  computed: {
    ...mapGetters("ListsModule", ["skills"])
  },
  watch: {
    parentCat() {
      this.$emit("clearError");
      if (this.parentCat !== "") {
        this.children = this.skills
          .find((a: { id: number }) => a.id == this.parentCat)
          .children.filter(a => !this.$props.currentSkills?.includes(a.id));
      } else {
        this.value = "";
        this.children = [];
      }
    },
    value() {
      this.$emit("clearError");
      this.$emit("selectSkill", this.value);
    }
  }
});
