
import { defineComponent } from "vue";
import LevelsDropdown from "@/components/dropdown/LevelsDropdown.vue";
import { Modal } from "bootstrap";

export default defineComponent({
  name: "edit-skill-modal",
  props: {
    skill: Object,
    clearModal: Boolean
  },
  components: {
    LevelsDropdown
  },
  data: () => ({
    experience: "" as string,
    levelId: "" as any,
    modal: {} as any,
    error: false
  }),
  watch: {
    skill() {
      this.experience = this.$props.skill?.experience;
      this.levelId =
        this.$props.skill?.level_id == null ? "" : this.$props.skill?.level_id;
      this.error = false;
    },
    experience() {
      this.error = false;
    }
  },
  methods: {
    validateAndSave() {
      this.$emit("saveEdited", {
        data: this.$data,
        userId: this.$props.skill?.user_id,
        pivotData: this.$props.skill
      });
      this.modal?.hide();
    }
  },
  mounted() {
    this.modal = new Modal(this.$refs.editSkillModalRef);
  }
});
