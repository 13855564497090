import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "badge rounded-pill bg-primary w-auto fs-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.getData)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
        _createElementVNode("span", null, _toDisplayString(_ctx.levelByID), 1)
      ]))
    : _createCommentVNode("", true)
}