<template>
  <AddNewSkillModal
    :clearModal="clearModal"
    @saveSkill="handleAddNew"
    :currentSkills="data"
  ></AddNewSkillModal>
  <EditSkillModal
    :skill="skillToEdit"
    @saveEdited="handleEditSkill"
  ></EditSkillModal>
  <DeleteModal @delete="deleteSkill"></DeleteModal>
  <div class="d-flex flex-wrap flex-stack mb-6">
    <!--begin::Title-->
    <h3 class="fw-bolder my-2">
      My skills
      <span class="fs-6 text-gray-400 fw-bold ms-1"
        >Overview of current skills and experience</span
      >
    </h3>
    <!--end::Title-->
    <!--begin::Controls-->
    <div class="d-flex my-2 ms-auto mt-5 mt-md-0">
      <!--begin::Search-->
      <div class="d-flex align-items-center position-relative me-4">
        <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
        <button
          class="btn btn-sm btn-success ms-auto"
          data-bs-toggle="modal"
          data-bs-target="#addSKillModal"
          @click="openAddNewModal"
        >
          <span class="svg-icon svg-icon-3">
            <inline-svg src="media/icons/duotone/Code/Plus.svg" />
          </span>
          Add new skill
        </button>
      </div>
    </div>
    <!--end::Controls-->
  </div>
  <LoadingSpinner v-if="loading"></LoadingSpinner>
  <el-empty
    description="No skills found"
    v-if="data.length == 0 && !loading"
  ></el-empty>
  <div v-if="!loading && data">
    <div class="row mt-4" v-for="parent in parentCategories" :key="parent.id">
      <h3 class="fw-bolder my-2 text-center text-md-start">
        {{ parent.name }}
        >
      </h3>
      <div
        class="col-12 col-md-3 mt-5"
        v-for="(skill, index) in skillsByParent(parent.id)"
        :key="index"
      >
        <div class="card h-100">
          <div
            class="card-header d-flex justify-content-between align-items-center "
          >
            <div class="card-title">
              <h4
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :title="skill.name"
              >
                {{
                  skill.name.length > 18
                    ? `${skill.name.slice(0, 18)}...`
                    : skill.name
                }}
              </h4>
            </div>
            <LevelBadge :experience="skill.skill_link"></LevelBadge>
          </div>
          <div class="card-body d-flex flex-column">
            <div class="mb-5 fs-7">
              Description:
              <span class="text-muted">{{ skill.worker_description }}</span>
            </div>
            <div class="mb-5 fs-7">
              Experience:
              <span class="text-muted">{{
                skill.skill_link.experience
                  ? skill.skill_link.experience
                  : "Nothing entered"
              }}</span>
            </div>
            <div
              class="d-flex flex-row justify-content-between align-items-center mt-auto"
            >
              <button
                class="btn btn-sm btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#editSkillModal"
                @click="openEditModal(skill.skill_link)"
              >
                Edit
              </button>
              <button
                class="btn btn-sm btn-danger"
                data-bs-toggle="modal"
                data-bs-target="#deleteModal"
                @click="openModal(skill.id)"
                :disabled="isAwaitingResponse"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import DeleteModal from "@/components/general/DeleteModal.vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import AddNewSkillModal from "@/components/my-skills/AddNewSkillModal.vue";
import LevelBadge from "@/components/my-skills/Misc/LevelBadge.vue";
import EditSkillModal from "@/components/my-skills/EditSkillModal.vue";

export default defineComponent({
  name: "my-skills-index",
  data: () => ({
    parentCategories: [],
    selectedSkillID: 0,
    skillToEdit: "",
    clearModal: false,
    userID: NaN,
    isEditing: ""
  }),
  components: {
    DeleteModal,
    LoadingSpinner,
    AddNewSkillModal,
    LevelBadge,
    EditSkillModal
  },
  computed: {
    ...mapGetters({ currentUser: "currentUser" }),
    ...mapGetters("MySkillsModule", ["data", "loading", "isAwaitingResponse"]),
    ...mapGetters("UsersModule", ["getSingleUser"]),
    userIdHandler() {
        if(this.currentUser.id == this.$route.params.id) return this.currentUser.id
        else return this.getSingleUser.id
    },
  },
  mounted() {
    if (this.$route.params.id == this.currentUser.id) this.handleFetch(this.currentUser.id);
    else if (this.$route.params.id !== this.currentUser.id && this.$can("skills_view_others", "all")) this.handleFetch(this.$route.params.id);
    else return 0;
  },
  methods: {
    ...mapActions("MySkillsModule", [
      "removeSkill",
      "fetchSkills",
      "resetState",
      "addNewSkill",
      "editSkill",
      "setLoading"
    ]),
    ...mapActions("LevelsModule", ["fetchData"]),
    handleFetch(id) {
      this.setLoading(true)
      this.fetchSkills(id).then(() => {
        this.getCurrentParentSkills();
      }).finally(() => { this.setLoading(false) });
      this.fetchData();
    },
    skillsByParent(id) {
      return this.data.filter(
        (a) => a.parent !== null && a.parent.id == id
      );
    },
    getCurrentParentSkills() {
      const skills = this.data.map(a => a.parent).filter(a => a !== null);
      const ids = skills.map(skill => skill.id);
      const filtered = skills.filter(({id}, index) => !ids.includes(id, index + 1))
      this.parentCategories = filtered;
    },
    openModal(id) {
      this.selectedSkillID = id;
    },
    openAddNewModal() {
      this.clearModal = true;
      this.$nextTick(() => {
        this.clearModal = false;
      });
    },
    deleteSkill() {
      const currentSkills = this.data.map(a => a.id);
      const payload = {
        skills: currentSkills.filter(a => a !== this.selectedSkillID),
        // eslint-disable-next-line @typescript-eslint/camelcase
        user_id: this.userIdHandler
      };
      this.removeSkill(payload).finally(() => {
        this.getCurrentParentSkills();
      });
    },
    handleAddNew(data) {
      let newSkills = [];
      newSkills = this.data.map((a) => a.id);
      newSkills.push(data.newSkillID);
      const payload = {
        skills: newSkills,
        // eslint-disable-next-line @typescript-eslint/camelcase
        user_id: this.userIdHandler,
        experience: data.experience,
        newSkillID: data.newSkillID
      };
      this.addNewSkill(payload).finally(() => {
        this.getCurrentParentSkills();
      });
    },
    openEditModal(data) {
      this.skillToEdit = data;
    },
    handleEditSkill(newData) {
      this.editSkill(newData);
    }
  },
  beforeUnmount() {
    this.resetState();
  }
});
</script>
