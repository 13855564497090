import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("span", {
      class: _normalizeClass(["label fs-6 required", _ctx.error && this.children == '' ? 'text-danger' : ''])
    }, "Skill category:", 2),
    _createVNode(_component_el_select, {
      clearable: "",
      modelValue: _ctx.parentCat,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.parentCat) = $event)),
      size: "large",
      class: _normalizeClass([_ctx.parentCat == '' && _ctx.error ? 'border border-danger rounded-1' : '', "w-100 mb-4"]),
      filterable: "",
      placeholder: "Select category"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.skills, (item) => {
          return (_openBlock(), _createBlock(_component_el_option, {
            key: item.id,
            label: item.name,
            value: item.id
          }, null, 8, ["label", "value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue", "class"]),
    _createElementVNode("span", {
      class: _normalizeClass(["label fs-6 required", _ctx.error && _ctx.value == '' ? 'text-danger' : ''])
    }, _toDisplayString(_ctx.children == "" ? "Select a parent category:" : "Select skill:"), 3),
    _createVNode(_component_el_select, {
      disabled: _ctx.children == '' ? true : false,
      class: _normalizeClass([_ctx.error && _ctx.value == '' ? 'border border-danger rounded-1' : '', "w-100"]),
      modelValue: _ctx.value,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value) = $event)),
      clearable: "",
      size: "large",
      filterable: "",
      placeholder: "Select skill"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.children, (item) => {
          return (_openBlock(), _createBlock(_component_el_option, {
            key: item.id,
            label: item.name,
            value: item.id
          }, null, 8, ["label", "value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["disabled", "class", "modelValue"])
  ], 64))
}