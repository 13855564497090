
import { defineComponent } from "vue";
import SkillsDropdown from "@/components/my-skills/SkillsDropdowns.vue";
import { Modal } from "bootstrap";

export default defineComponent({
  name: "add-new-skill-modal",
  components: {
    SkillsDropdown
  },
  props: {
    clearModal: Boolean,
    currentSkills: Array as () => any[]
  },
  data: () => ({
    skillID: "" as any,
    textarea: "",
    error: false,
    modal: {} as any,
    key: 0 as number
  }),
  methods: {
    validateAndSave() {
      if (this.skillID == "") {
        this.error = true;
      } else {
        this.$emit("saveSkill", {
          newSkillID: this.skillID,
          experience: this.textarea
        });
      }
    }
  },
  watch: {
    clearModal() {
      this.error = false;
      this.skillID = "";
      this.textarea = "";
      this.key++;
    }
  },
  mounted() {
    this.modal = new Modal(this.$refs.skillModalRef);
  },
  computed: {
    currentSkillsIDs(): any {
      return this.$props.currentSkills?.map(a => a.id);
    }
  }
});
